import { jsxs as x, jsx as e } from "react/jsx-runtime";
import { css as l, styled as t } from "styled-components";
import { forwardRef as a } from "react";
const d = {
  xLarge: `
    padding: 16px 40px;
    font-size: 20px;
    line-height: 32px;
  `,
  large: `
    padding: 16px 30px;
    font-size: 18px;
    line-height: 24px;
  `,
  medium: `
    padding: 12px 22px;
    font-size: 16px;
    line-height: 24px;
  `,
  small: `
    padding: 8px 20px;
    font-size: 14px;
    line-height: 24px;
  `,
  xSmall: `
    padding: 8px 16px;
    font-size: 12px;
    line-height: 16px;
  `
}, B = l`
  && {
    background: ${({ theme: o }) => o.colors.black80};
    cursor: default;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: ${({ theme: o }) => o.colors.black80};
        text-decoration: none;
      }
    }
  }
`, C = t.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: fit-content;
  border-radius: 8px;
  border: none;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  height: fit-content;
  font-family: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  color: ${({ theme: o }) => o.colors.black100};
  ${({ size: o }) => d[o] ?? d.small};
  ${({ disabled: o }) => o && B};
  ${({ $fit: o }) => o && l`
      width: fit-content;
      height: fit-content;
    `};
`, w = t.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-left: ${({ $margin: o, $position: n }) => n === "left" ? 0 : `${o}px`};
    margin-right: ${({ $margin: o, $position: n }) => n === "right" ? 0 : `${o}px`};
  }
`, u = ({ position: o, Icon: n, size: r }) => {
  const s = y[r], c = ["small", "xSmall"].includes(r) ? 4 : 8;
  return n ? /* @__PURE__ */ e(w, { $position: o, $margin: c, children: /* @__PURE__ */ e(n, { size: s }) }) : null;
}, i = a(
  ({
    size: o = "medium",
    disabled: n = !1,
    onClick: r,
    children: s,
    LeftIcon: c,
    RightIcon: p,
    tabIndex: g,
    fit: h = !1,
    name: b,
    value: $,
    className: f,
    type: v = "button",
    ...k
  }, m) => /* @__PURE__ */ x(
    C,
    {
      size: o,
      disabled: n,
      onClick: r,
      tabIndex: g,
      $fit: h,
      name: b,
      value: $,
      className: f,
      type: v,
      ref: m,
      ...k,
      children: [
        /* @__PURE__ */ e(u, { Icon: c, size: o, position: "left" }),
        s,
        /* @__PURE__ */ e(u, { Icon: p, size: o, position: "right" })
      ]
    }
  )
), y = {
  xLarge: 32,
  large: 32,
  medium: 24,
  small: 16,
  xSmall: 16
}, I = t(i)`
  background: ${({ theme: o }) => o.colors.blue60};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${({ theme: o }) => o.colors.blue50};
    }
  }
`, Q = a(({ as: o, ...n }, r) => /* @__PURE__ */ e(I, { ref: r, forwardedAs: o, ...n })), z = t(i)`
  background: ${({ theme: o }) => o.colors.darkBlue40};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${({ theme: o }) => o.colors.darkBlue30};
    }
  }
`, U = a((o, n) => /* @__PURE__ */ e(z, { ref: n, ...o })), L = t(i)`
  background: ${({ theme: o }) => o.colors.purple40};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${({ theme: o }) => o.colors.purple30};
    }
  }
`, V = a((o, n) => /* @__PURE__ */ e(L, { ref: n, ...o })), S = l`
  && {
    pointer-events: none;
    background: transparent;
    color: ${({ theme: o }) => o.colors.black80};
    cursor: default;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: transparent;
      }
    }

    & > svg {
      fill: ${({ theme: o }) => o.colors.black80};
    }
  }
`, j = t(i)`
  color: ${({ theme: o }) => o.colors.blue50};
  background: transparent;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme: o }) => o.colors.blue30};

      svg {
        fill: ${({ theme: o }) => o.colors.blue30};
      }
    }
  }
  ${({ disabled: o }) => o && S};
`, D = a((o, n) => /* @__PURE__ */ e(j, { ref: n, ...o })), G = t(D)`
  padding: 0;
`, P = a((o, n) => /* @__PURE__ */ e(G, { ref: n, ...o })), W = l`
  && {
    background: transparent;

    &:hover {
      background: transparent;
    }

    & > svg {
      fill: ${({ theme: o }) => o.colors.black80};
    }
  }
`, A = t(i)`
  background: transparent;
  width: fit-content;
  padding: 0;

  & > svg {
    fill: ${({ theme: o }) => o.colors.blue60};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: transparent;

      svg {
        fill: ${({ theme: o }) => o.colors.blue50};
      }
    }
  }

  ${({ disabled: o }) => o && W};
`, X = a(({ children: o, ...n }, r) => /* @__PURE__ */ e(A, { ref: r, "aria-label": "icon-button", ...n, children: o })), M = l`
  && {
    background: transparent;

    &:hover {
      background: transparent;
    }

    & > svg {
      fill: ${({ theme: o }) => o.colors.black80};
    }
  }
`, O = t(i)`
  background: transparent;
  width: fit-content;
  padding: 0;

  & > svg {
    fill: ${({ theme: o }) => o.colors.darkBlue40};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: transparent;

      svg {
        fill: ${({ theme: o }) => o.colors.darkBlue30};
      }
    }
  }

  ${({ disabled: o }) => o && M};
`, Y = a(({ children: o, ...n }, r) => /* @__PURE__ */ e(O, { ref: r, ...n, children: o })), R = l`
  && {
    background: transparent;

    &:hover {
      background: transparent;
    }

    & > svg {
      fill: ${({ theme: o }) => o.colors.black80};
    }
  }
`, T = t(i)`
  background: transparent;
  width: fit-content;
  padding: 0;

  & > svg {
    fill: ${({ theme: o }) => o.colors.purple40};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: transparent;

      svg {
        fill: ${({ theme: o }) => o.colors.purple30};
      }
    }
  }

  ${({ disabled: o }) => o && R};
`, Z = a(({ children: o, ...n }, r) => /* @__PURE__ */ e(T, { ref: r, ...n, children: o })), q = l`
  && {
    color: ${({ theme: o }) => o.colors.black100};

    &:hover {
      color: ${({ theme: o }) => o.colors.black100};
    }
  }
`, E = t(i)`
  color: ${({ theme: o }) => o.colors.blue50};
  background: ${({ theme: o }) => o.colors.blue95};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme: o }) => o.colors.blue30};
      background: ${({ theme: o }) => o.colors.blue90};
    }
  }

  ${({ disabled: o }) => o && q};
`, _ = a((o, n) => /* @__PURE__ */ e(E, { ref: n, ...o })), F = l`
  && {
    background: transparent;

    &:hover {
      background: transparent;
    }

    & > svg {
      fill: ${({ theme: o }) => o.colors.black80};
    }
  }
`, H = t(i)`
  background: transparent;
  width: fit-content;
  padding: 0;

  & > svg {
    fill: ${({ theme: o }) => o.colors.blue95};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: transparent;

      svg {
        fill: ${({ theme: o }) => o.colors.blue90};
      }
    }
  }

  ${({ disabled: o }) => o && F};
`, oo = a(({ children: o, ...n }, r) => /* @__PURE__ */ e(H, { ref: r, ...n, children: o })), no = t(P)`
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  ${({ theme: o, $danger: n }) => n && l`
      color: ${o.colors.red60};

      &:hover {
        color: ${o.colors.red40};
      }

      @media (hover: none) {
        &:hover {
          color: ${({ theme: r }) => r.colors.red60};
        }
      }
    `};
`;
export {
  Q as BlueButton,
  X as BlueIconButton,
  i as Button,
  U as DarkBlueButton,
  Y as DarkBlueIconButton,
  y as IconSize,
  _ as LightBlueButton,
  oo as LightBlueIconButton,
  P as LinkButton,
  no as OptionButton,
  V as PurpleButton,
  Z as PurpleIconButton,
  D as TextButton
};
